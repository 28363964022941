.notice-agreement-wrap {
  font-size: 14px;
  padding-top:24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 22px;
  color: #7D7D7D;
  opacity: 1;
  display:flex;
  box-sizing:border-box;
  align-items: center;
}
.notice-agreement-checked{
  width: 16px;
  height: 16px;
  background: url('./../../imagesData/checked.png') no-repeat center;
  background-size: 100%;
  cursor: pointer;
}
.notice-agreement-notChecked{
  width: 16px;
  height: 16px;
  background: url('./../../imagesData/noChecked.png') no-repeat center;
  background-size: 100%;
  cursor: pointer;
}
.notice-agreement-data{
  margin-left: 6px;
}
.notice-agreement-data>a{
  color:#5E24FF;
}